<template>
  <div class="content-wrapper">
    <div class="orders main-content">

      <div class="page-header">
        <h1>Payment Overview</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Payment Name" @search="searchPayment" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Reference ID">
                <el-input v-model="searchForm.reference_id"></el-input>
              </el-form-item>
              <el-form-item label="Principal Employee Name">
                <el-input v-model="searchForm.employee_name"></el-input>
              </el-form-item>
              <el-form-item label="Type" style="max-width: 550px;">
                <el-select v-model="searchForm.type" placeholder="Select the payments type">
                  <el-option label="Income" value="Income"></el-option>
                  <el-option label="Outcome" value="Outcome"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Status" style="max-width: 550px;">
                <el-select v-model="searchForm.status" placeholder="Select the status">
                  <el-option label="Pending" value="Pending"></el-option>
                  <el-option label="Accepted" value="Accept"></el-option>
                  <el-option label="Rejected" value="Reject"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>

        <div class="tool">
          <router-link :to="{name:'CreatePayment'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="payment-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Reference ID</th>
              <th scope="col">Principal Employee Name</th>
              <th scope="col">Payment Name</th>
              <th scope="col">Payment Type</th>
              <th scope="col">Status</th>
              <th scope="col">Amount</th>
              <th scope="col">Create Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment in payments">
              <td>{{payment.reference_id}}</td>
              <td>{{payment.employee_name}}</td>
              <td>{{payment.payment_name}}</td>
              <td>{{payment.type}}</td>
              <td>{{payment.status}}</td>
              <td>${{payment.amount}}</td>
              <td>{{ formatDateTime(payment.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td class="action">
                <router-link :to="{name:'Payment', params: {id: payment.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deletePayment(payment.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// @ is an alias to /src
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'Payments',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        reference_id: '',
        employee_name: '',
        type: '',
        status: '',
      },
      originPayments: [],
      payments: [],
    }
  },
  mounted(){
    this.loadPayments();
  },
  methods:{
    async loadPayments(){
      const loginInfo = Common.getLoginInfo();
      try{
        const payments = await Financial.loadAllPayment(this.apiUrl, loginInfo);
        this.originPayments = payments;
        this.payments = payments;
      }catch(err){
        console.log(err);
      }
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.payments = this.originPayments;
    },
    searchPayment(textSearchValue){
      this.searchForm['payment_name'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newPayments = Common.filterItems(searchFilter, this.originPayments);
      this.payments = newPayments;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async deletePayment(paymentId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Financial.deletePayment(this.apiUrl, paymentId, loginInfo);
        const newPayments = this.payments.filter(function(item) {
          return item.id !== paymentId;
        });
        this.payments = newPayments;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.action{
  a{
    display: inline-block;
    margin-right: 10px;
  }
  .delete-link{
    color: red;
  }
}
</style>
